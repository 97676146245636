import React, { useState, useEffect, useRef } from 'react';
import './App.css';
import characterSrc from './character.png';
import webumpSrc from './webump.png';
import craftyCanineSrc from './crafty_canine.png';
import seiyanSrc from './seiyan.png';
import dobSrc from './dob.png';
import bulletSrc from './bullet.png';
import stickerSrc from './sticker.png';

function App() {
  const canvasRef = useRef(null);
  const characterRef = useRef({ x: 50, y: window.innerHeight / 2, width: 50, height: 50, dx: 0, dy: 0 });
  const [characterImage, setCharacterImage] = useState(null);
  const [bulletImage, setBulletImage] = useState(null);
  const [characterLoaded, setCharacterLoaded] = useState(false);
  const [bulletLoaded, setBulletLoaded] = useState(false);
  const [projectiles, setProjectiles] = useState([]);
  const [enemies, setEnemies] = useState([]);
  const [score, setScore] = useState(0);
  const [nftCounts, setNftCounts] = useState({
    craftyCanine: 0,
    dob: 0,
    webump: 0,
    seiyan: 0
  });
  const [canShoot, setCanShoot] = useState(true);
  const [gameOver, setGameOver] = useState(false);
  const [showHowTo, setShowHowTo] = useState(true);
  const [paused, setPaused] = useState(false);

  const enemyTypes = [
    { src: craftyCanineSrc, points: 15 },
    { src: dobSrc, points: 10 },
    { src: webumpSrc, points: 5 },
    { src: seiyanSrc, points: 1 },
  ];

  useEffect(() => {
    const canvas = canvasRef.current;
    canvas.width = window.innerWidth;
    canvas.height = window.innerHeight;
    const ctx = canvas.getContext('2d');

    const charImg = new Image();
    charImg.src = characterSrc;
    charImg.onload = () => {
      setCharacterImage(charImg);
      setCharacterLoaded(true);
    };

    const bullImg = new Image();
    bullImg.src = bulletSrc;
    bullImg.onload = () => {
      setBulletImage(bullImg);
      setBulletLoaded(true);
    };

    const handleKeyDown = (e) => {
      if (!gameOver) {
        if (e.key === 'ArrowRight') characterRef.current.dx = 5;
        if (e.key === 'ArrowLeft') characterRef.current.dx = -5;
        if (e.key === 'ArrowUp') characterRef.current.dy = -5;
        if (e.key === 'ArrowDown') characterRef.current.dy = 5;
        if (e.key === ' ' && canShoot) shootProjectile();
        if (e.key === 'Escape') togglePause();
      }
    };

    const handleKeyUp = (e) => {
      if (!gameOver && !paused) {
        if (e.key === 'ArrowRight' || e.key === 'ArrowLeft') characterRef.current.dx = 0;
        if (e.key === 'ArrowUp' || e.key === 'ArrowDown') characterRef.current.dy = 0;
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    window.addEventListener('keyup', handleKeyUp);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('keyup', handleKeyUp);
    };
  }, [canShoot, gameOver, paused]);

  useEffect(() => {
    if (!gameOver && !showHowTo && !paused) {
      const interval = setInterval(() => {
        updateGame();
      }, 1000 / 60); // 60 FPS
      return () => clearInterval(interval);
    }
  }, [projectiles, enemies, gameOver, showHowTo, paused]);

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    drawGame(ctx);
  });

  const shootProjectile = () => {
    const { x, y, width, height } = characterRef.current; // Get the current character position
    const newProjectile = {
      x: x + width,
      y: y + height / 2 - 10,
      width: 40,
      height: 20,
      dx: 10
    };
    setProjectiles((prev) => [...prev, newProjectile]);
    setCanShoot(false);
    setTimeout(() => setCanShoot(true), 500); // Limit shooting to 2 projectiles per second
  };

  const spawnEnemy = () => {
    const rand = Math.random();
    let enemyType;

    if (rand < 0.5) {
      enemyType = enemyTypes[3]; // Seiyan (50% chance)
    } else if (rand < 0.75) {
      enemyType = enemyTypes[2]; // Webump (25% chance)
    } else if (rand < 0.95) {
      enemyType = enemyTypes[1]; // Dob (20% chance)
    } else {
      enemyType = enemyTypes[0]; // Crafty Canine (5% chance)
    }

    const newEnemy = {
      x: window.innerWidth,
      y: Math.random() * (window.innerHeight - 50),
      width: 50,
      height: 50,
      dx: -5,
      src: enemyType.src,
      points: enemyType.points,
    };

    setEnemies((prev) => [...prev, newEnemy]);
  };

  const updateGame = () => {
    characterRef.current.x += characterRef.current.dx;
    characterRef.current.y += characterRef.current.dy;

    if (characterRef.current.x < 0) characterRef.current.x = 0;
    if (characterRef.current.x + characterRef.current.width > window.innerWidth) characterRef.current.x = window.innerWidth - characterRef.current.width;
    if (characterRef.current.y < 0) characterRef.current.y = 0;
    if (characterRef.current.y + characterRef.current.height > window.innerHeight) characterRef.current.y = window.innerHeight - characterRef.current.height;

    setProjectiles((prevProjectiles) => {
      return prevProjectiles
        .map((proj) => ({ ...proj, x: proj.x + proj.dx }))
        .filter((proj) => proj.x < window.innerWidth);
    });

    setEnemies((prevEnemies) => {
      return prevEnemies
        .map((enemy) => ({ ...enemy, x: enemy.x + enemy.dx }))
        .filter((enemy) => enemy.x + enemy.width > 0);
    });

    checkCollisions();
    checkGameOver();

    if (Math.random() < 0.02) {
      spawnEnemy();
    }
  };

  const checkCollisions = () => {
    setProjectiles((prevProjectiles) => {
      setEnemies((prevEnemies) => {
        const remainingEnemies = prevEnemies.filter((enemy) => {
          const hit = prevProjectiles.some((proj) => {
            return (
              proj.x < enemy.x + enemy.width &&
              proj.x + proj.width > enemy.x &&
              proj.y < enemy.y + enemy.height &&
              proj.y + proj.height > enemy.y
            );
          });
          if (hit) {
            setScore((prevScore) => prevScore + enemy.points);
            setNftCounts((prevCounts) => {
              const updatedCounts = { ...prevCounts };
              if (enemy.src === craftyCanineSrc) updatedCounts.craftyCanine += 1;
              if (enemy.src === dobSrc) updatedCounts.dob += 1;
              if (enemy.src === webumpSrc) updatedCounts.webump += 1;
              if (enemy.src === seiyanSrc) updatedCounts.seiyan += 1;
              return updatedCounts;
            });
          }
          return !hit;
        });
        return remainingEnemies;
      });

      return prevProjectiles.filter((proj) => {
        return !enemies.some((enemy) => {
          return (
            proj.x < enemy.x + enemy.width &&
            proj.x + proj.width > enemy.x &&
            proj.y < enemy.y + enemy.height &&
            proj.y + proj.height > enemy.y
          );
        });
      });
    });
  };

  const checkGameOver = () => {
    const collided = enemies.some((enemy) => {
      return (
        characterRef.current.x < enemy.x + enemy.width &&
        characterRef.current.x + characterRef.current.width > enemy.x &&
        characterRef.current.y < enemy.y + enemy.height &&
        characterRef.current.y + characterRef.current.height > enemy.y
      );
    });

    if (collided) {
      setGameOver(true);
      const playAgainButton = document.getElementById('play-again-button');
      playAgainButton.style.display = 'block';
    }
  };

const resetGame = () => {
  characterRef.current = { x: 50, y: window.innerHeight / 2, width: 50, height: 50, dx: 0, dy: 0 };
  setProjectiles([]);
  setEnemies([]);
  setScore(0);
  setNftCounts({ craftyCanine: 0, dob: 0, webump: 0, seiyan: 0 });
  setGameOver(false);
  setPaused(false);
  
  const playAgainButton = document.getElementById('play-again-button');
  playAgainButton.style.display = 'none';

  const canvas = canvasRef.current;
  const ctx = canvas.getContext('2d');
  ctx.clearRect(0, 0, canvas.width, canvas.height); // Clear the entire canvas
};



const drawGame = (ctx) => {
  ctx.clearRect(0, 0, window.innerWidth, window.innerHeight);

  if (showHowTo) {
    drawHowToScreen(ctx);
    return;
  }

  // Draw character
  if (characterLoaded) {
    ctx.drawImage(characterImage, characterRef.current.x, characterRef.current.y, characterRef.current.width, characterRef.current.height);
  }

  // Draw projectiles
  if (bulletLoaded) {
    projectiles.forEach((proj) => {
      ctx.drawImage(bulletImage, proj.x, proj.y, proj.width, proj.height);
    });
  } else {
    ctx.fillStyle = 'red';
    projectiles.forEach((proj) => {
      ctx.fillRect(proj.x, proj.y, proj.width, proj.height);
    });
  }

  // Draw enemies
  enemies.forEach((enemy) => {
    const img = new Image();
    img.src = enemy.src;
    ctx.drawImage(img, enemy.x, enemy.y, enemy.width, enemy.height);
  });

  // Draw score
  ctx.fillStyle = 'black';
  ctx.font = '24px Arial';
  ctx.textAlign = 'center';
  ctx.fillText(`Score: ${score}`, window.innerWidth / 2, 40);

  if (gameOver || paused) {
    ctx.fillStyle = 'rgba(0, 0, 0, 0.5)';
    ctx.fillRect(0, 0, window.innerWidth, window.innerHeight);

    const img = new Image();
    img.src = stickerSrc;
    img.onload = () => {
      ctx.drawImage(img, window.innerWidth / 2 - 100, window.innerHeight / 2 - 200, 200, 200);
    };

    ctx.fillStyle = 'white';
    ctx.font = '48px Arial';
    ctx.textAlign = 'center';
    ctx.fillText(gameOver ? 'Game Over' : 'Paused', window.innerWidth / 2, window.innerHeight / 2 + 30);

    if (gameOver) {
      ctx.font = '24px Arial';
      const startY = window.innerHeight / 2 + 100;
      const gap = 40;
      let totalScore = 0;

      const drawNFTCount = (src, count, points, y) => {
        const nftImg = new Image();
        nftImg.src = src;
        nftImg.onload = () => {
          ctx.drawImage(nftImg, window.innerWidth / 2 - 150, y, 50, 50);
          const score = count * points;
          ctx.fillText(`${count} x ${points} = ${score}`, window.innerWidth / 2, y + 30);
          totalScore += score;
        };
      };

      drawNFTCount(craftyCanineSrc, nftCounts.craftyCanine, 15, startY);
      drawNFTCount(dobSrc, nftCounts.dob, 10, startY + gap);
      drawNFTCount(webumpSrc, nftCounts.webump, 5, startY + gap * 2);
      drawNFTCount(seiyanSrc, nftCounts.seiyan, 1, startY + gap * 3);

      setTimeout(() => {
        ctx.fillText(`Total Score: ${totalScore}`, window.innerWidth / 2, startY + gap * 4 + 30);
        const playAgainButton = document.getElementById('play-again-button');
        playAgainButton.style.top = `${startY + gap * 4 + 70}px`;
        playAgainButton.style.left = '50%';
        playAgainButton.style.transform = 'translateX(-50%)';
        playAgainButton.style.display = 'block';
      }, 100);
    }

    const resumeButton = document.getElementById('resume-button');
    resumeButton.style.display = paused ? 'block' : 'none';
  }
};



 


  const drawHowToScreen = (ctx) => {
    ctx.fillStyle = '#87CEEB'; // Sky blue
    ctx.fillRect(0, 0, window.innerWidth, window.innerHeight);

    ctx.fillStyle = 'black';
    ctx.font = '48px Arial';
    ctx.textAlign = 'center';
    ctx.fillText('How To Play', window.innerWidth / 2, 100);

    ctx.font = '24px Arial';
    ctx.fillText('Collect NFTs to gain points:', window.innerWidth / 2, 200);

    enemyTypes.forEach((enemy, index) => {
      const img = new Image();
      img.src = enemy.src;
      img.onload = () => {
        ctx.drawImage(img, window.innerWidth / 2 - 100, 240 + index * 60, 50, 50);
        ctx.fillText(`= ${enemy.points} points`, window.innerWidth / 2 + 40, 275 + index * 60);
      };
    });

    ctx.fillText('Press SPACE to shoot', window.innerWidth / 2, window.innerHeight - 250);

    const button = document.getElementById('start-game-button');
    button.style.display = 'block';
  };

  const startGame = () => {
    setShowHowTo(false);
    setPaused(false);
    const startButton = document.getElementById('start-game-button');
    startButton.style.display = 'none';
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    drawGame(ctx); // Redraw the game canvas to update the initial score position
  };

  const togglePause = () => {
    setPaused((prevPaused) => {
      const newPaused = !prevPaused;
      if (!newPaused) {
        const resumeButton = document.getElementById('resume-button');
        resumeButton.style.display = 'none';
      }
      return newPaused;
    });
  };

  const resumeGame = () => {
    setPaused(false);
    const button = document.getElementById('resume-button');
    button.style.display = 'none';
  };

  return (
    <div>
      <canvas ref={canvasRef} style={{ display: 'block', margin: '0 auto', paddingTop: '20px' }}></canvas>
      <button id="start-game-button" onClick={startGame} style={{ display: showHowTo ? 'block' : 'none', position: 'absolute', left: '50%', top: '90%', transform: 'translate(-50%, -50%)', padding: '10px 20px', fontSize: '24px', backgroundColor: '#fff', border: '2px solid #000', borderRadius: '5px', cursor: 'pointer' }}>Start Game</button>
      <button id="resume-button" onClick={resumeGame} style={{ display: 'none', position: 'absolute', left: '50%', top: '60%', transform: 'translate(-50%, -50%)', padding: '10px 20px', fontSize: '24px', backgroundColor: '#fff', border: '2px solid #000', borderRadius: '5px', cursor: 'pointer' }}>Resume</button>
      <button id="play-again-button" onClick={resetGame} style={{ display: 'none', position: 'absolute', left: '50%', top: '60%', transform: 'translate(-50%, -50%)', padding: '10px 20px', fontSize: '24px', backgroundColor: '#fff', border: '2px solid #000', borderRadius: '5px', cursor: 'pointer' }}>Play Again</button>
    </div>
  );
}

export default App;
